const innovatorQuote = "A value network is the context within which a firm establishes a cost structure and operating processes and works with suppliers and channel partners in order to respond profitably to the common needs of a class of customers."
const innovatorQuoteRef = "Clayton M. Christensen - The Innovator's Solution"

export const langDict = {
	en_projectName: "Phil Network",
	en_homeLink: "Home",
	en_irLink: "Invest",
	en_contactLink: "Contact",
	en_headIntroducing: "Introducing",
	en_headSemBiz: "The Phil Network",
	en_headSlogan: "Organizational networking, projects and trust",
	en_enterApplication: "Sign Up Now *It's free*",

	en_whatIsItHead: "What?",
	en_whatIsIt: `A digital platform for organizations, mirroring the real world's relations. Structured as a social network.
Allows organizations to **connect, communicate accountably, authenticate, approve contracts and trade** - within a live model of their actual value network.`,

	en_whyMakeItHead: "Why?",
	en_whyMakeIt: `The business world **consists of networks** - of people and organizations. This fundemental context should be reflected in our information systems.
By digitalizing the relation networks, the platform helps their users **keep track of connections, resources, roles and communications**.
A digitalized value network enables users to **browse, document and publish their relations** and allows for simple yet **robust generic automation**.`,

	en_whoUseItHead: "Who?",
	en_whoUseIt: `Any firm or organization that participate in some form of network to deliver value to their followers.
Anyone who needs to **model their business**, and who wants to use the model for more than static presentation slides.
Anyone who wants to **guarantee their identity** and establish **digital trust** on the Internet.`,

	en_chatAnnouncement: "**Look!** *We are offering a class leading B2B chat.* **Try it out!**",

	en_questionManage: "How do you **manage contacts and track progress** on cross-team projects?",
	en_questionSimplifyCollaboration: "How do you **enable your vendors to collaborate** - to meet your goals?",
	en_questionDocumentRelations: "How do you **visualize and prove your relations** - for investors or new team members?",
	en_questionFairTenders: "How do you **arrange efficient and just tenders? Sharing information and supporting** all parties?",

	en_leadSolution: "The Phil Network is the obvious answer!",
	en_leadSolutionLong: `Why?
	Because most value adding business practices, such as trade, consultancy, contract approval, short or long term project collaboration
	and the like - involves several people and organizations. These are serving specific roles in the processes.
	Most business systems of today fails to address this. They either focus and cater to one of the roles specifically, forgets the personal dimension, or ignores the role aspect all together.
	The Phil Network welcomes all organizations, and treats them as natural members of their different value networks, carefully honoring individual employees and leaders.
	This multi dimensional approach, in the cloud, enables a range of new structural opportunities, just waiting to benefit your business.`,
	en_innovatorQuote: innovatorQuote,
	en_innovatorQuoteRef: innovatorQuoteRef,

	en_appFeaturesHeader: "Features",

	en_headIr: "Invest in the Phil Network",
	en_irContent: `The business model of the Phil Network is to provide common networked services for all businesses, at extremely low cost, with transactions lightly taxed. By keeping 
the basic use free, and efforts low, we hope to encourage organizations to join and utilize the network.

We start with basic connectivity, communication, security and trade - involves economic features and then continues on to become a fully featured business management system.

If you want to know more about the opportunities of this venture - visit the [contact](/en/contact) page to get in touch.`,
	en_headContact: "Contact Information",
	en_contactContent: `The Phil Network is developed by Digitalt Verdinettverk AS, a Norwegian startup.

For more information about the system or the company, contact the founder [Jørgen Tvedt on LinkedIn](https://www.linkedin.com/in/jtvedt)
	`,

	en_prodblogLink: "Product Blog",
	en_techblogLink: "Tech Blog",

	no_projectName: "Phil",
	no_homeLink: "Hjem",
	no_irLink: "Invester",
	no_contactLink: "Kontakt",
	no_headIntroducing: "Velkommen til",
	no_headSemBiz: "Phil-plattformen",
	no_headSlogan: " \\- organisasjonsnettverk, prosjekter og tillit",
	no_enterApplication: "Prøv den nå! *Gratis*",


	no_chatAnnouncement: "**Se!** *Vi tilbyr en klasseledende B2B chat.* **Prøv den ut!**",

	no_whatIsItHead: "Hva?",
	no_whatIsIt: `En digital platform for team og bedrifter som speiler virkelighetens relasjoner. Strukturert som et sosialt nettverk.
Lar organisasjoner **identifisere** seg og **kommunisere** etterettelig. Gjør det enkelt å **organisere projsekter og aktiviter på kryss av team**.  Etter hvert kommer signering av avtaler og handel.  Alt i en **dynamisk modell av ditt verdinettverk**.
`,

	no_whyMakeItHead: "Hvorfor?",
	no_whyMakeIt: `Virkelighetens bedriftsverden består av nettverk med folk og organisasjoner.  Denne strukturen bør gjenspeiles i informasjonssystemene våre.
Phil holder orden på relasjoner og roller, lar brukeren dokumentere og søke i eksterne koblinger og er skapt for automatisering.`,

	no_whoUseItHead: "Hvem?",
	no_whoUseIt: `Alle organisasjoner som deltar i et nettverk for å skape verdier.
Alle som må kunne modellere sin virksomhet og som ønsker å benytte modellen til noe mer enn statiske presentasjoner.
Alle som må kunne garantere sin identitet og opprette digital tillit på Internet.`,


	no_questionManage: "Hvordan følger du opp **hvilke relasjoner og nøkkelpersoner** ditt team er avhengig av?",
	no_questionSimplifyCollaboration: "Hvordan gjør du det **enkelt for dine leverandører å samarbeide** - for å nå dine mål?",
	no_questionDocumentRelations: "Hvordan **dokumenterer du kunderelasjoner og nettverk** for investorer eller nyansatte?",
	no_questionFairTenders: "Hvordan arrangerer du **effektive og rettferdige anbud?** Med deling av informasjon og svar på spørsmål?",
	

	no_leadSolution: "Phil-plattformen er det naturlige svaret på spørsmålene over!",
	no_leadSolutionLong: `Hvorfor? 
	Fordi de fleste verdiskapende forretningsprosesser, som handel, kontraktsinngåelse, konsulentbistand, kort eller langvarig prosjektsammarbeid og lignende - involverer flere personer fra forskjellige organisasjoner med sine spesifikke roller.
	De fleste bedriftssystemer i dag fokuserer og tjener en av disse rollene spesifikt - de overser persondimensjonen og / eller de behandler alle deltagere som like.
	Det digitale verdinettverket tar i mot alle organisasjoner og plasserer dem som naturlige medlemmer i deres forskjellige verdinettverk, samtidig som menneskene bak alltid respekteres.
	Denne flerdimensjonale innfalsvinkelen, i skyen, åpner for et bredt spekter av nye muligheter, som bare venter på at du melder deg inn.`,

	no_innovatorQuote: innovatorQuote,
	no_innovatorQuoteRef: innovatorQuoteRef,

	no_appFeaturesHeader: "Funksjoner",

	no_headIr: "Invester i Phil-nettverket",
	no_irContent: `Forretningsmodellen i Phil-nettverket er å tilby forretningstjenster mellom deltagere i verdinettverk gratis eller til en lav pris, med betalbar adgang til nettverkets markedsplass.

Vi starter med helt enkel kommunikasjon, sikkerhet og handel - og jobber videre via økonomifunksjoner mot et komplett bedriftsstyringssystem.

Besøk [kontakt](/no/contact)-siden for mer informasjon om selskapet og ideen.
	`,

	no_headContact: "Kontaktinformasjon",
	no_contactContent: `Phil-nettverket utvikles av Digitalt Verdinettverk AS, en norsk oppstartsbedrift.

For mer informasjon om produktet eller selskapet ta kontakt med etablerer [Jørgen Tvedt på LinkedIn](https://www.linkedin.com/in/jtvedt)
	`,

	no_signIn: "Logg inn",
	en_signIn: "Sign in",
	no_signOut: "Logg av",
	en_signOut: "Log out",
	no_secretText: "Phil-nettverketet er best!",
	en_secretText: "Viva la Digital Value Network!",
	no_theSecret: "Hemmeligheten: ",
	en_theSecret: "The secret: ",

	no_prodblogLink: "Produktblogg",
	no_techblogLink: "Teknisk blogg",
}

export const enFeatureDict: {header: string, text: string}[] = [
	{
		header: "Communicate",
		text: "Set up phone or video calls throught the web browser. Send text messages or emails - without sharing contact details. Drag & drop **files and documents**.  **Ask for formal approval** on critical activities or changes.",
	},
	{
		header: "Projects",
		text: "Create activities and projects in a layered structure. Add people and resources where they are needed. **Invite** interal or external teams. Aggregate estimates, costs, remaining work, issues and key figures.",
	},
	{
		header: "Chat for your web page",
		text: `**Improve sales** by reaching out to prospects on you web page. Also a full blown **team collector**, allowing visitors to register and connect their team on the Phil Network. This makes it the perfect B2B chat.`
	},
	{
		header: "Anonymous Connections",
		text: "Connect to a potential vendor without disclosing your identity. Let the Phil Network serve as your agent, sharing only what you want",
	},
]

export function map(lang: string, code: string) {
	return langDict[lang + "_" + code] || '#INV'
}
