import * as React from 'react'
import { LangCompSelector } from './LangCompSelector';
const ReactMarkdown = require('react-markdown')

export interface IProps {
	lang: string
	features: {header: string, text: string}[]
}

export class Features extends React.Component<IProps, any> {
	render() {
		return <div className="app-features">
			<LangCompSelector {...this.props} compName={"appFeaturesHeader"} />
			{this.props.features.map((x, i) => <div key={"k_" + i} className="app-feature">
					<div className="glyphicon glyphicon-arrow-right" />
					<div>
						<div className="header"><ReactMarkdown source={x.header}/></div>
						<div className="text"><ReactMarkdown source={x.text}/></div>
					</div>
				</div>)
			}
		</div>
	}
}
