import * as React from 'react';
import * as _ from 'lodash'

import { langDict, enFeatureDict } from '../LanguageDb'
import { Features } from './Features';

const ReactMarkdown = require('react-markdown')

interface ILangCompSelectorProps {
	lang: string
	compName: string
	md?: boolean
	className?: string
}


export class LangCompSelector extends React.Component<ILangCompSelectorProps, any> {

	_resolveComponent(): JSX.Element {
		if (this.props.compName === "appFeaturesComp")
			return <Features features={enFeatureDict} {...this.props}/>

		const code = this.props.lang + "_" + this.props.compName

		if (langDict[code])
			return (<ReactMarkdown source={langDict[code]} />)
		return (<ReactMarkdown source={code} />)
	}

	render() {
		return (<div className={_.kebabCase(this.props.compName) + " " + (this.props.className || "")}>
			{
				this._resolveComponent()
			}
		</div>)
	}
}