import * as React from 'react';
import {LangCompSelector} from './LangCompSelector'

interface IProps {
	lang: string
}

export class IrTab extends React.Component<IProps, any> {
	render() {
		return (
			<div>
				<main className="section-ir">
					<div className="container ">
						<div className="featurette-title"><LangCompSelector lang={this.props.lang} compName="headIr" /></div>
						<div className="featurette-paragraph"><LangCompSelector lang={this.props.lang} compName="irContent" /></div>
					</div>
				</main>
			</div>
		)
	}
};