import * as React from 'react';
import {LangCompSelector} from './LangCompSelector'

interface IProps {
	lang: string
}

export class ContactTab extends React.Component<IProps, any> {
	render() {
		return (
			<div>
				<main className="section-contact">
					<div className="container ">
						<div className="featurette-title"><LangCompSelector lang={this.props.lang} compName="headContact" /></div>
						<div className="featurette-paragraph"><LangCompSelector lang={this.props.lang} compName="contactContent" /></div>
					</div>
				</main>
			</div>
		)
	}
};

