import * as React from 'react';

export interface IProps {
	lang: string,
	url: string
}

export class LangSelector extends React.Component<IProps, any> {
	private getUrl(lang:string) {
		return this.props.url.replace(/(.*\/)+(\w{2})(\/.+)/, "$1" + lang + "$3")
	}
	render() {
		let langList = ["no", "en"]
		return (
			<div className="langSelector">
				{
					langList.map(x => <a href={this.getUrl(x)} key={"lang_" + x} ><div className={"lang_img_option lang_img_" + x + " " + (x == this.props.lang ? "lang_active" : "")}><img src="/img/languages.png" /></div></a>)
				}
			</div>
		)
	}
};