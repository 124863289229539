import * as React from 'react';
import {LangCompSelector} from './LangCompSelector'
import {LangSelector} from './LangSelector'

export interface IProps {
	lang: string
	pageState: string
	url: string
}

interface IItemLinkProps {
	code: string
	pageState: string
	lang: string
	selectItem: (x: string) => void
}

class ItemLink extends React.Component<IItemLinkProps, any> {
	_selectClass(code: string): string {
		return this.props.pageState === code ? "active" : ""
	}

	render() {
		return (<li className={this._selectClass(this.props.code) }><a href={"/" + this.props.lang + "/" + this.props.code} onClick={e => this.props.selectItem(this.props.code) }>
				<LangCompSelector lang={this.props.lang} compName={this.props.code + "Link"} />
			</a></li>)
	}
}

export class Navbar extends React.Component<IProps, any> {
	selectItem(code) {
		console.log(code)
	}

	render() {
		return (
			<nav className="navbar">
				<div className="container-fluid">
					<div className="navbar-header">
						<button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#exCollapsingNavbar2">
							{String.fromCharCode(9776)}
						</button>
						<div className="navbar-brand">
							<a href={"/" + this.props.lang + "/" + "home"}>
								<img src={"/img/logo.png"} />
								<LangCompSelector lang={this.props.lang} compName={"projectName"} />
							</a></div>
					</div>
					<div className="collapse navbar-collapse" id="exCollapsingNavbar2">
						<ul className="nav navbar-nav">
							<ItemLink code="home" pageState={this.props.pageState} selectItem={e => this.selectItem(e)} lang={this.props.lang} />
							<ItemLink code="prodblog" pageState={this.props.pageState} selectItem={e => this.selectItem(e) } lang={this.props.lang} />
							<ItemLink code="techblog" pageState={this.props.pageState} selectItem={e => this.selectItem(e) } lang={this.props.lang} />
							<ItemLink code="ir" pageState={this.props.pageState} selectItem={e => this.selectItem(e) } lang={this.props.lang} />
							<ItemLink code="contact" pageState={this.props.pageState} selectItem={e => this.selectItem(e) } lang={this.props.lang} />
						</ul>
						<ul className="nav navbar-nav navbar-right">
							<li><LangSelector lang={this.props.lang} url={this.props.url} /></li>
						</ul>
					</div>
				</div>
			</nav>
		)
	}
};