/// <reference path="../../hostContract/contract.d.ts" />

// Can't put this into the contract, for some reason - might be overridden later
// Never mind, have to check explicitly on the hostOutputs
declare const window: HostContracts.MangledWindow

import * as React from 'react';
import {Navbar} from './Navbar'
import {HomeTab} from './HomeTab'
import {IrTab} from './IrTab'
import {Blog} from './Blog'
import {ContactTab} from './ContactTab'
import { sumoCrapTimeout } from './constants';


interface IMainState {
	doneLoading: boolean;
}

export interface IMainProps {
	lang: string
	pageState: string
	url: string
	posts?: any[]
	expandBlog?: boolean
	selectedPostSlug?: string
}

export class Main extends React.Component<IMainProps, IMainState> {
	constructor(props: IMainProps) {
		super(props)
		this.state = { doneLoading: false }
	}

	componentDidMount() {
		setTimeout(() => this.setState({doneLoading: true}), sumoCrapTimeout)
	}

	render() {
		const {IframeOuter} = this.state.doneLoading && (typeof hostOutputs !== "undefined") ? hostOutputs : (({IframeOuter: p => <div className="no-chat-available-server-side" />} as any) as HostContracts.IHostOutputs)

		const blogArgs = {lang: this.props.lang, posts: this.props.posts, expandBlog: this.props.expandBlog, code: this.props.pageState, selectedPostSlug: this.props.selectedPostSlug}
		return (
			<div>
				<IframeOuter iframeUrl={"https://philapp.co/s59df46550000100060/chatRoom/"} />
				<Navbar lang={this.props.lang} pageState={this.props.pageState} url={this.props.url}/>
				{
					(() => {
					switch (this.props.pageState) {
						case "home":
							return <HomeTab lang={this.props.lang} />
						case "ir":
							return <IrTab lang={this.props.lang} />
						case "prodblog":
							return <Blog {...blogArgs} />
						case "techblog":
							return <Blog {...blogArgs} />
						case "contact":
							return <ContactTab lang={this.props.lang} />
						default:
							return <span>Not defined</span>
					}})()
				}
			</div>
		)
	}
};