import * as React from 'react'

const ReactMarkdown = require('react-markdown')

export interface IPost {
	slug: string
	title: string
	authorName: string
	authorPhoto: string
	date: number
	categories: {name: string}[]
	content: string
	teaser: string
}

export interface IProps  {
	posts: IPost[]
	expandBlog?: boolean
	selectedPostSlug?: string
	lang: string
	code: string
}

export const imageServerUrl = "https://dvnblog-b44e.restdb.io/media/"

export class Blog extends React.Component<IProps, any> {
	render() {
		return <div className="blog-root container">
			{this.props.posts.filter(x => !this.props.expandBlog || x.slug === this.props.selectedPostSlug).map(post =>
				<div className="blog-post " key={post.slug}>
					<div className="featurette-title">{post.title}</div>
					<div className="author-section">
						{ post.authorPhoto && <img className="author-photo" src={`${imageServerUrl}${post.authorPhoto}`} style={{"maxWidth": "10em"}} /> }
						<div className="author-and-date-section">
							<span>{post.authorName}</span>
							<div className="post-date-section">
								<span className="glyphicon glyphicon-time"></span>
								<span>{`Posted on ${new Date(post.date).toDateString()}`}</span>
								{
									post.categories && post.categories.map((cat, i) =>
										<span className="label label-default" key={"ke" + i}>{cat.name}</span>)
								}
							</div>
						</div>
					</div>
					<div className="lead"><ReactMarkdown className="post-content" source={post.teaser}/></div>
					{post.slug === this.props.selectedPostSlug && <div className="lead"><ReactMarkdown className="post-content" source={post.content}/></div>}
					{this.props.expandBlog ? <script type="IN/Share"></script> :
						<a href={`/${this.props.lang}/${this.props.code}/${post.slug}`}>Read more</a> }
				</div>
			)}
		</div>
	}
}
