import * as React from 'react';
import {LangCompSelector} from './LangCompSelector'
import { sumoCrapTimeout } from './constants';

export interface IHomeTabProps {
	lang: string
}

export class HomeTab extends React.Component<IHomeTabProps, {showChatAnnouncement: boolean, doneLoading?: boolean, activeQuestion: number}> {

	eventHandler = null
	questionSection: HTMLDivElement = null

	constructor(props) {
		super(props)
		this.state = {showChatAnnouncement: true, activeQuestion: 0}
		this.eventHandler = (e) => this.onScroll(e)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.eventHandler)
		setTimeout(() => this.setState({doneLoading: true}), sumoCrapTimeout)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.eventHandler)
	}

	onScroll(e: React.UIEvent<any>) {
		const height = window.innerHeight
		const activeQuestion = Math.max(0, Math.min(2, Math.floor((window.scrollY - this.questionSection.offsetTop) * 3 / (height))))

		this.setState({showChatAnnouncement: window.scrollY < 20, activeQuestion})
	}

	render() {
		const lprop = {lang: this.props.lang}
		const questClass = (i) => this.state.activeQuestion === i ? " activeQuestion" : " inactiveQuestion"
		return (
			<div>
				{this.state.doneLoading &&
					<div className={`chat-announcement-container ${this.state.showChatAnnouncement ? "" : "not-cool-hide"}` }>
						<LangCompSelector {...lprop} compName="chatAnnouncement" />
						<div className="glyphicon glyphicon-arrow-right" />
					</div>
				}
				<main className="section-main">
					<div className="container ">
						<div className="lead"><LangCompSelector {...lprop} compName="headIntroducing" /></div>
						<div className="caption"><LangCompSelector {...lprop} compName="headSemBiz" /></div>
						<div className="lead"><LangCompSelector {...lprop} compName="headSlogan" /></div>
						<a className="enter-button" href="https://philapp.co"><LangCompSelector {...lprop} compName="enterApplication" /></a>
					</div>
				</main>
				<div className="section-question">
					<div className="container">
						<div><LangCompSelector {...lprop} compName="questionManage" /></div>
					</div>
				</div>
				<div className="section-what-why-who">
					<div className="container ">
						<div className="what-section featurette" >
							<div className="featurette-title"><LangCompSelector {...lprop} compName="whatIsItHead" /></div>
							<div className="featurette-paragraph"><LangCompSelector {...lprop} compName="whatIsIt" /></div>
						</div>
						<div className="why-section featurette" >
							<div className="featurette-title"><LangCompSelector {...lprop} compName="whyMakeItHead" /></div>
							<div className="featurette-paragraph"><LangCompSelector {...lprop} compName="whyMakeIt" /></div>
						</div>
						<div className="who-section featurette" >
							<div className="featurette-title"><LangCompSelector {...lprop} compName="whoUseItHead" /></div>
							<div className="featurette-paragraph"><LangCompSelector {...lprop} compName="whoUseIt" /></div>
						</div>
					</div>
				</div>
				<div className="section-question multiple" ref={e => this.questionSection = e}>
					<div className="container">
						<div><LangCompSelector {...lprop} compName="questionSimplifyCollaboration" className={questClass(0)}/></div>
						<div><LangCompSelector {...lprop} compName="questionDocumentRelations" className={questClass(1)}/></div>
						<div><LangCompSelector {...lprop} compName="questionFairTenders" className={questClass(2)}/></div>
					</div>
				</div>
				<div className="section-solution">
					<div className="container ">
						<div className="featurette-title"><LangCompSelector {...lprop} compName="leadSolution" /></div>
						<div><LangCompSelector {...lprop} compName="leadSolutionLong" /></div>
					</div>
				</div>
				<div className="section-app-features">
					<div className="container">
						<LangCompSelector {...lprop} compName="appFeaturesComp" />
					</div>
				</div>
				<div className="section-quote">
					<div className="container ">
						<div className="dummy col-xs-4" />
						<div className="col-xs-4 quoteArea">
							<div className="innovatorQuoteDiv"><LangCompSelector {...lprop} compName="innovatorQuote" /></div>
							<div><LangCompSelector {...lprop} compName="innovatorQuoteRef" /></div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};